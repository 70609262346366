@import "tailwindcss/base";
@import "tailwindcss/components";
@import "tailwindcss/utilities";

:root {
  --color-first: rgba(38, 36, 36, 0.647);

  --color-second: rgba(161, 225, 42, 0.647);
  --color-third: rgba(49, 190, 211, 0.952);
  --body-color: rgb(240, 240, 240);

  --font-PT: "PT Serif", serif;
  --font-Zilla: "Zilla Slab", serif;
  --link-color: #0aa7ef;
  --text-color: #000;
  --color-dark: #2a2a2a;
  --border-color: #2a2a;

  --darkmode-color: var(--color-dark);
  --dark-text-color: #fff;
  --dark-link-color: var(--color-first);
  --dark-border-color: #fff;
}

/* Dark mode toggle */
.dark-mode-checkbox:checked ~ .wrapper {
  --body-color: var(--darkmode-color);
  --text-color: var(--dark-text-color);
  --link-color: var(--dark-link-color);
  --border-color: var(--dark-border-color);
}

* {
  margin: 0;
  padding: 0;
  box-sizing: border-box;
}

#darkMode {
  position: fixed;
  top: 8.5rem;
  left: 1rem;
  display: none;
}

.toggle {
  position: fixed;
  top: 8.5rem;
  left: 2rem;
  cursor: pointer;
}

html {
  scroll-behavior: smooth;
}

body {
  min-height: 100vh;
  line-height: 1.5rem;
  overflow-x: hidden;
}

.wrapper {
  background: var(--body-color);
  font-family: "PT Serif", serif;
}

a {
  text-decoration: none;
  color: inherit;
}

.flex {
  display: flex;
  gap: 2rem;
}

.primary-header {
  justify-content: space-between;
  align-items: center;
  background: var(--body-color);
  padding: 1rem 2rem;
  box-shadow: 0 2px 8px yellowgreen;
  z-index: 999;
  position: -webkit-sticky;
  position: sticky;
  top: 0;
  height: 100px;
  color: var(--text-color);

  .logo {
    position: relative;
    justify-content: center;
    align-items: center;
    gap: 3rem;
    font-size: 30px;

    .line::before {
      content: " ";
      background: rgba(255, 115, 0, 0.9725490196);
      height: 110%;
      width: 3px;
      position: absolute;
      top: 0;
      left: 42%;
    }
  }
  .right #check,
  .right .checkBtn {
    display: none;
  }

  .right .checkBtn {
    cursor: pointer;
    font-size: 1.5rem;
    float: right;
    padding-right: 2.5rem;
  }
  .right .primary-navigation {
    list-style: none;
    gap: 2.5rem;
    font-weight: bold;
  }
  .right .primary-navigation li a:hover {
    background: #ecefe6b3;
    color: #0c0101;
    border-radius: 5px;
    box-shadow: 0 0 5px #b9f560, 0 0 25px #b9f560, 0 0 50px #b9f560,
      0 0 100px #ebf3df;
  }
}

@media (max-width: 768px) {
  .primary-header .right .checkBtn {
    display: block;
  }
  .primary-header .right .primary-navigation {
    width: 15rem;
    height: 100vh;
    background: var(--body-color);
    box-shadow: 0 3px 5px 0 #000;
    flex-direction: column;
    position: fixed;
    top: 6.3rem;
    right: -15rem;
    z-index: 999;
    transition: 0.5sec ease;
  }
  .primary-header .right .primary-navigation li {
    padding-left: 1rem;
  }
  .primary-header .right .primary-navigation li:nth-child(1) {
    padding: 2rem 0 0 1rem;
  }
  .primary-header .right #check:checked ~ ul {
    right: 0;
    transition: 0.5s ease;
  }
}
@media (max-width: 480px) {
  .primary-header .logo h1 {
    font-size: 1.1rem;
  }
  .primary-header .logo div .line {
    font-size: 1.3rem;
  }
  .primary-header .logo div .line::before {
    left: 40%;
  }
}

.grid {
  display: grid;
  gap: 1rem;
}

.section1 {
  background: var(--color-first);
  grid-template-areas: "one two";
  margin: 8rem auto 0.01rem auto;
  background-size: cover;
  width: 90vw;
  position: relative;
  padding: 0rem;

  .section1::before {
    content: "Developer";
    position: absolute;
    right: 0;
    top: -2.5rem;
    font-style: bold;
    font-size: 3rem;
    color: #a0a540;
  }
  .left {
    grid-area: one;
    display: flex;
    justify-content: center;
    align-items: center;
    padding-bottom: 1rem;
    img {
      width: 500px;
      margin-left: -2.5rem;
      margin-bottom: -15px;
    }
  }

  .right {
    grid-area: two;
    flex-direction: column;
    gap: 0.9rem;
    justify-content: center;
  }
  .right div .name {
    line-height: 4rem;
    font-size: 3.5rem;
    color: rgb(219, 224, 247);
    font-family: var(--font-PT);
  }
  .right div .tag {
    color: whitesmoke;
    font-size: 2rem;
    font-family: var(--font-PT);
    font-weight: bold;
    margin-top: 1rem;
  }
  .right a {
    width: -webkit-max-content;
    width: -moz-max-content;
    width: max-content;
    border: 0;
    padding: 0.8rem 1.2rem;
    border-radius: 5px;
    background: var(--color-third);
    cursor: pointer;
    margin-top: 2rem;
    border-radius: 15px;
    display: inline-block;
  }
  .right a i {
    font-size: 1.5rem;
    color: black;
  }
}

.dark-mode-checkbox:checked + label i {
  /* Styles for the moon icon when dark mode is active */
  color: var(--dark-text-color);
}

.text-container {
  color: #fbecec; /* White text for better contrast on a red background */
  padding: 10px; /* Add padding to provide some space around the text */
}

.name {
  font-size: 0.2em; /* Adjust the font size of the name */
  margin-bottom: 12px; /* Add some space below the name */
}

.portfolio-text {
  font-size: 1.2em; /* Adjust the font size of the paragraph */
  line-height: 1.5; /* Set the line height for better readability */
}
/* new changes */

.forms a {
  position: relative;
  display: flex;
  align-items: center;
  padding: 10px 20px;
  color: #130e0f;
  font-size: 18px;
  font-weight: bold;
  text-decoration: none;
  overflow: hidden;
  transition: 0.5s;
  letter-spacing: 2px;
}

.forms a:hover {
  background: #c1f079b3;
  color: #0c0101;
  border-radius: 5px;
  box-shadow: 0 0 5px #b9f560, 0 0 25px #b9f560, 0 0 50px #b9f560,
    0 0 100px #b9f560;
}

.forms a span {
  position: absolute;
  display: block;
}

.forms .right a span:nth-child(1) {
  top: 0;
  left: -100%;
  width: 100%;
  height: 2px;
  background: linear-gradient(90deg, transparent, #018b0a);
  animation: btn-anim1 1s linear infinite;
}

@keyframes btn-anim1 {
  0% {
    left: -100%;
  }
  50%,
  100% {
    left: 100%;
  }
}

.section1 .right a span:nth-child(2) {
  top: -100%;
  right: 0;
  width: 2px;
  height: 100%;
  background: linear-gradient(180deg, transparent, #018b0a);
  animation: btn-anim2 1s linear infinite;
  animation-delay: 0.25s;
}

@keyframes btn-anim2 {
  0% {
    top: -100%;
  }
  50%,
  100% {
    top: 100%;
  }
}

.section1 .right span:nth-child(3) {
  bottom: 0;
  right: -100%;
  width: 100%;
  height: 2px;
  background: linear-gradient(270deg, transparent, #018b0a);
  animation: btn-anim3 1s linear infinite;
  animation-delay: 0.5s;
}

@keyframes btn-anim3 {
  0% {
    right: -100%;
  }
  50%,
  100% {
    right: 100%;
  }
}

.section1 .right a span:nth-child(4) {
  bottom: -100%;
  left: 0;
  width: 2px;
  height: 100%;
  background: linear-gradient(360deg, transparent, #018b0a);
  animation: btn-anim4 1s linear infinite;
  animation-delay: 0.75s;
}

@keyframes btn-anim4 {
  0% {
    bottom: -100%;
  }
  50%,
  100% {
    bottom: 100%;
  }
}

/* upto here  */
@media (max-width: 768px) {
  .section1 {
    grid-template-areas: "one" "two";
    padding-bottom: 0rem;
  }
  .section1 .right {
    align-items: center;
  }
}
@media (max-width: 480px) {
  .section1 .left img {
    width: 100%;
  }
}

/* section 2  */

.ring {
  width: 200px;
  height: 200px;
  border: 0px solid black; /* Make sure to adjust border-width to your preference */
  border-radius: 50%;
  position: absolute;
}
.ring:nth-child(1) {
  border-bottom-width: 8px;
  border-color: #ef0a9b;
  animation: rotate1 2s linear infinite;
}
.ring:nth-child(2) {
  border-right-width: 8px;
  border-color: #0aef3f;
  animation: rotate2 2s linear infinite;
}

.ring:nth-child(3) {
  border-top-width: 8px;
  border-color: #0f8ac7;
  animation: rotate3 2s linear infinite;
}

@keyframes rotate1 {
  0% {
    transform: rotateX(35deg) rotateY(-45deg) rotateZ(0deg);
  }
  100% {
    transform: rotateX(35deg) rotateY(-45deg) rotateZ(360deg);
  }
}
@keyframes rotate2 {
  0% {
    transform: rotateX(50deg) rotateY(10deg) rotateZ(0deg);
  }
  100% {
    transform: rotateX(50deg) rotateY(10deg) rotateZ(360deg);
  }
}
@keyframes rotate3 {
  0% {
    transform: rotateX(35deg) rotateY(55deg) rotateZ(0deg);
  }
  100% {
    transform: rotateX(35deg) rotateY(55deg) rotateZ(360deg);
  }
}

.loading-container {
  display: flex;
  justify-content: center;
  align-items: center;
  margin-top: 150px;
}
.loading-text {
  font-weight: bold;
  color: #a0a540;
}

.section2 {
  margin: 6rem auto 0;

  width: 90vw;
  height: 45vw;
  position: relative;
  padding: 50px;
}
.skills-container:before {
  content: "SkillsSet";
  position: absolute;
  right: 0;
  top: -1.8rem;
  font-style: bold;
  font-size: 3rem;
  color: #a0a540;
}

.skills-container {
  display: flex;
  justify-content: center;
  align-items: center;
  margin-top: 290px;
}

/* CSS for circle containers */
.circle {
  width: 100px; /* Set the width and height to make a circle */
  height: 100px;
  display: flex; /* Use flexbox to center the icon vertically and horizontally */
  justify-content: center;
  align-items: center;
  margin: 10px; /* Add margin for spacing */
  border-radius: 50%; /* Use border-radius to create a circle */
}
/* html  */
.circle-container1 {
  border: 8px solid #178394; /* Set border with desired color */
  box-shadow: 0 0 0 4px #2f5b8e;
}
/* css  */
.circle-container2 {
  border: 8px solid #179447; /* Set border with desired color */
  box-shadow: 0 0 0 4px #d38181;
}
/* //react container  */
.circle-container3 {
  border: 8px solid #61d7be; /* Set border with desired color */
  box-shadow: 0 0 0 4px #3d5f05;
}
/* js  */
.circle-container4 {
  border: 6px solid #b99814; /* Set border with desired color */
  box-shadow: 0 0 0 4px #af6b6b;
}
/* tailwind  */
.circle-container5 {
  border: 8px solid #5b9772; /* Set border with desired color */
  box-shadow: 0 0 0 4px #bc8484;
}
/* firebase  */
.circle-container6 {
  border: 6px solid #8565b9; /* Set border with desired color */
  box-shadow: 0 0 0 4px #8f498d;
}

.section2 .skills-container div i {
  font-size: 4rem;
  color: rgba(0, 100, 128, 0.8);
}

.skills-container {
  display: flex;
  justify-content: center;
  align-items: center;
  margin-top: 220px; /* Adjust top margin to create space for the loading container */
  order: 1; /* Set skills container to appear below loading container */
}

/* Adjustments for smaller screens */
@media (max-width: 768px) {
  .loading-container {
    flex-direction: column;
  }
  .skills-container {
    flex-direction: row;
    flex-wrap: wrap;
    margin-right: 10px;
  }

  .loading-container {
    margin-top: -10px; /* Adjust margin for spacing */
    margin-left: 160px;
  }
  .ring {
    font-size: 2px;
  }

  .skills-container {
    margin-top: 100px;
  }
}
@media only screen and (max-width: 320px) {
  /* Styles for iPhone 4 */
  .section2 .loading-container {
    margin-top: -10px; /* Adjust margin for spacing */
    margin-left: 100px;
  }
  .ring {
    font-size: 1px;
  }
}

/* certifications section  */
.section3 {
  grid-template-areas: "one two";
  margin: 16rem auto 0.01rem auto;
  background-size: cover;
  width: 90vw;
  position: relative;
  padding: 8rem;
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
}

.section3::before {
  content: "Certifications";
  position: absolute;
  right: 0;
  top: -2rem;
  font-style: bold;
  font-size: 3rem;
  color: #a0a540;
}

.ag-courses_item {
  flex-basis: calc(
    33.33333% - 20px
  ); /* Adjust the width to fit three items in one row */
  margin: 0 10px 20px; /* Adjust margin for spacing between items */

  overflow: hidden;

  border-radius: 30px;
}

.ag-courses-item_link {
  display: block;
  padding: 30px 20px;
  background-color: #344833;
  overflow: hidden;
  position: relative;
  width: 200px; /* Set a fixed width for the card container */
  height: 250px;
}
.ag-courses-item_link:hover,
.ag-courses-item_link:hover .ag-courses-item_date {
  text-decoration: none;
  color: #fff;
}
.ag-courses-item_link:hover .ag-courses-item_bg {
  -webkit-transform: scale(10);
  -ms-transform: scale(10);
  transform: scale(10);
}
.ag-courses-item_bg {
  height: 120px;
  width: 120px;
  background-color: #7f78d1;
  z-index: 1;
  position: absolute;
  top: -75px;
  right: -75px;

  border-radius: 50%;

  -webkit-transition: all 0.5s ease;
  -o-transition: all 0.5s ease;
  transition: all 0.5s ease;
}
.ag-courses-item_title {
  height: 65px;
  font-weight: bold;
  font-size: 17.5px;
  color: #c5ce21;
  overflow: hidden; /* Hide any overflow text */
  text-overflow: ellipsis; /* Add ellipsis (...) for overflow text */
  white-space: normal;
  z-index: 2;
  position: relative;
}
.ag-courses-item_details {
  font-size: 14px;
  color: #fff;
  z-index: 2;
  position: relative;
}

.ag-courses-item_company {
  font-weight: bold;
  color: #5ec3cf;

  -webkit-transition: color 0.5s ease;
  -o-transition: color 0.5s ease;
  transition: color 0.5s ease;
}

.ag-courses_item.even .ag-courses-item_bg {
  background-color: #51805b; /* Apply style to even-indexed elements */
}

.ag-courses_item.odd .ag-courses-item_bg {
  background-color: #cf9400; /* Apply style to odd-indexed elements */
}

@media only screen and (max-width: 768px) {
  .section3 {
    margin-top: 450px;
  }
}
@media (max-width: 375px) {
  .section3 {
    margin-top: 790px;
  }
}

/* projects  */
.section4 {
  flex-direction: column;
  justify-content: center;
  align-items: center;
  gap: 3rem;
  padding-bottom: 70px;

  .title {
    font-size: 3rem;
    color: #a0a540;
  }
  .projects-container {
    width: 90vw;

    padding: 3.5rem 2rem 0 2rem;
    grid-template-columns: repeat(auto-fit, minmax(350px, 1fr));
  }
  .projects-container .project-card {
    flex-direction: column;
    color: var(--text-color);
  }
  img {
    width: 100%;
    height: 250px;
    border-radius: 12px;
  }

  .projects-container .project-card .botton p {
    font-size: 1rem;
    font-weight: bold;
    display: flex;
    justify-content: center;
  }
  .projects-container .project-card .botton p:nth-child(2) {
    margin-top: 1.2rem;
    font-weight: bold;
  }
}

@media (max-width: 480px) {
  .section4 .projects-container {
    width: 100vw;
    padding: 3rem 0.5rem;
  }
}

/* about me  */
.section5 {
  flex-direction: column;
  justify-content: center;
  align-items: center;
  color: var(--text-color);

  .heading {
    font-size: 2.5rem;
    color: #a0a540;
  }

  .about-container {
    padding: 5rem;

    .left {
      width: 50%;
      flex-direction: column;
      gap: 2rem;
      padding: 0 2rem;
    }
    .gradimage {
      position: relative;
    }
    .title {
      font-size: 1.3rem;
      font-weight: bold;
      color: #1b5a91;
    }
    .bold {
      font-weight: bold;
    }
    .skills-color {
      color: #a0a540;
    }
    .right {
      width: 50%;
      flex-direction: column;
      gap: 2rem;
      padding: 0 2rem;
    }
    h3 {
      font-weight: 500;
      font-style: normal;
      font-family: "Protest Riot", sans-serif;
    }
    h4 {
      padding: 5px; /* Add some padding for better readability */
      border-radius: 20px; /* Optional: Add rounded corners */
      box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1); /* Optional: Add a subtle shadow */
    }
    a {
      color: var(--link-color);
      font-size: 1.4rem;
    }
    .right > div {
      flex-direction: column;
    }
    .right > div div {
      display: flex;
      gap: 1rem;
    }
    .right > div div span {
      padding: 0.5rem 0.7rem;
    }
    .right > div div span:hover {
      cursor: pointer;
      background: rgb(182, 202, 50);
      border-radius: 0.8rem;
    }
  }
}

@media (max-width: 1024px) {
  .section5 .about-container {
    flex-direction: column;
  }
  .section5 .about-container .left,
  .section5 .about-container .right {
    width: 100%;
    text-align: center;
  }
  .section5 .about-container .right .div {
    justify-content: center;
  }
}
@media (max-width: 480px) {
  .section5 .about-container {
    width: 100vw;
  }

  .section5 .about-container .right h1 {
    font-size: 1.5rem;
    justify-content: center;
  }
}

/* contact  */
.section6 {
  flex-direction: column;
  justify-content: center;
  align-items: center;
  color: var(--text-color);
  .title {
    font-size: 2rem;
    margin-top: 103px;
  }
  h2 {
    font-weight: bold;
  }
  .social {
    padding: 2rem 0;
    gap: 5rem;
  }
  .social i {
    font-size: 3rem;
  }
  .social i:nth-child(5) {
    color: #0e76a8;
  }
  .email-section {
    margin-top: 0.1rem;
    flex-direction: column;
    align-items: center;
    gap: 2rem;
    .email {
      background: var(--border-color);
      display: flex;
      align-items: center;
      padding: 1rem 2rem;
      border-radius: 100px;
      gap: 1rem;
      cursor: pointer;
    }
    .email a {
      color: rgb(104, 6, 6);
    }
    .email .send {
      color: var(--body-color);
    }
  }
}

@media (max-width: 480px) {
  .section6 .social {
    gap: 2rem;
  }
}
@media only screen and (max-width: 320px) {
  /* Styles for iPhone 4 */
  .section6 .social {
    gap: 1rem;
  }
}

/* footer section  */
footer {
  color: #a0a540;
  .footer-container {
    flex-direction: column;
    align-items: center;
    justify-content: center;

    .footer-top {
      justify-content: space-around;
      align-items: flex-start;
      padding: 4rem;
      width: 80%;
      text-align: center;

      .sitemap {
        font-size: 18px;
        font-weight: bold;
      }
      .location {
        font-size: 18px;
        font-weight: bold;
      }
      .ophours {
        font-size: 18px;
        font-weight: bold;
      }
      div {
        ul {
          margin-top: 1rem;
          flex-direction: column;
          gap: 0.5rem;
          list-style: none;
          font-size: 16px;

          li:hover {
            color: var(--border-color);
            cursor: pointer;
          }
        }
      }
    }
    .footer-bottom {
      flex-direction: column;
      text-align: center;
      gap: 2rem;
      padding-bottom: 1rem;
    }
  }
}

@media (max-width: 640px) {
  .footer-container {
    flex-direction: column;
    align-items: center;
  }
  .footer-top {
    flex-direction: column;
    align-items: center;
  }

  .sitemap,
  .location,
  .ophours {
    text-align: center;
    margin: 0 auto; /* Center the div */
    max-width: 80%; /* Limit the width for better readability */
  }
  .text-xl {
    font-size: 1.2rem;
  }
  .font-bold {
    font-weight: bold;
  }
  .mb-3 {
    margin-bottom: 0.5rem;
  }
  .mb-6 {
    margin-bottom: 1rem;
  }
}

.scroll-to-top {
  position: fixed;
  bottom: 2rem;
  right: 2rem;
  background: var(--color-second);
  width: 40px;
  height: 40px;
  border-radius: 50%;
  z-index: 999;
  cursor: pointer;
  .scroll-btn {
    font-size: 1.5rem;
    position: absolute;
    left: 50%;
    top: 50%;
    transform: translate(-50%, -50%);
    -webkit-animation: arrow infinite 2s ease;
    animation: arrow infinite 2s ease;
  }
}

@-webkit-keyframes arrow {
  0% {
    top: 50%;
  }
  50% {
    top: 70%;
    opacity: 0.1;
  }
  100% {
    top: 40%;
  }
}

@keyframes arrow {
  0% {
    top: 50%;
  }
  50% {
    top: 70%;
    opacity: 0.1;
  }
  100% {
    top: 40%;
  }
}

/* //practise css  below*/
